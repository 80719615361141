export default {
    // 显示的消息文本
    // 为object参照c-text的props
    msg:null,

    // 自定义颜色
    // 支持c-bg-开头的主题色
    // - 支持linear-gradient(to bottom right,#AD18F9,#05DFC7)渐变色，只支持两种颜色的渐变
    bgColor:'rgba(17, 17, 17, 0.7)',

    // icon
    // 为object参照c-icon的props
    icon:null,

    // icon位置，left,top
    iconPosition:'left',

    // 位置，top,bottom,center
    position:'center',

    // 展示时间，单位ms
    duration:2000
}