export default {
    // 表单数据
    model:{},
    // 表单校验规则
    // 带有function自定义校验的规则，需要使用this.$refs.myForm.setRules();
    rules:{},

    // 是否禁用
    disabled:null,

    // 是否只读
    readonly:null,

    // form-item的margin设置
    itemMargin:null,
    // form-item的padding设置
    itemPadding:null,

    // 表单错误信息提示方式，none/text/toast
    errorType:'text',

    // 错误样式
    errorStyle:{},

    // label样式
    // 参考c-text的props
    labelPros:{},

    // label 位置，left，top
    labelPosition:'left',
    // label 宽度
    labelWidth:null,
    // label 居中方式，left/center/right
    labelAlign:'left',
    // label和内容之间的间距
    labelGutter:null,
    // 是否显示表单域的边框
    // none,top,bottom
    border:'bottom',
    // 边框样式
    // 参考c-divider的props
    borderStyle:{}
}