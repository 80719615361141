/**
 * 获取页面的路由信息
 * https://uniapp.dcloud.net.cn/tutorial/page.html#getcurrentpages
 * @param back
 */
import toUrlParams from "./toUrlParams";
import isEmpty from "./isEmpty";

function getPages(back){
    const pages = getCurrentPages();

    const pagesLen = pages.length;
    const res = [];
    for (let i = pagesLen-1;i>-1;i--){
        if(typeof back !== 'undefined' && i > pagesLen+back) continue;
        const currPage = pages[i].$page;

        const page = {
            path:'/'+currPage.route,
            query:currPage.options,
        };
        page.queryStr = isEmpty(currPage.options)?'':toUrlParams(currPage.options);
        page.fullPath = page.queryStr?page.path+'?'+page.queryStr:page.path;
        page.pageLength = pagesLen;
        page.title = currPage.$holder?currPage.$holder.navigationBarTitleText:''
        res.push(page);
        if(typeof back !== 'undefined' && i === pagesLen+back) break;
    }
    return typeof back !== 'undefined'?res[Math.abs(back)]:res
}

export default getPages