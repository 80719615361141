function install(app) {
  const _this = app.config.globalProperties;
  _this.$c.http.interceptors.request.use((config) => {
    if(_this.$store.state.authToken){
      config.header.Authorization = `Bearer ${_this.$store.state.authToken.token}`;
    }
    return config
  }, config => { // 可使用async await 做异步操作
    return Promise.reject(config)
  })


  _this.$c.http.interceptors.response.use((response) => {
    const res = response.data;
    if(res){
      if(response.config.method === "UPLOAD" && res.state === "SUCCESS"){
        return res;
      }else if(res.code === 'OK'){
        return res.data;
      }
    }
    _this.$c.toast(res.message)
    return Promise.reject(response.data)
  }, (response) => { // 错误时
    const res = response.data;
    if(!res) {
      _this.$c.toast(response.errMsg)
    } else if(_this.$c.isPlainObject(res) && res.code === 'Unauthorized') {
      _this.$store.dispatch('logout')
      _this.$c.toast('未授权').then(()=>{
        const pages = _this.$c.getPages();
        _this.$c.route('/pages/passport/login?backUrl='+encodeURIComponent(pages[0].fullPath),{
          type:pages.length===1?'rto':'to'
        });
      })
      _this.$c.toast('未授权或登录失效')
    }else{
      _this.$c.toast(_this.$c.isPlainObject(res)?res.message:response.statusCode+'错误');
    }
    return Promise.reject(response)
  })
}

export default install