export default {
  // 日程名
  // 用于事件中返回
  name:null,

  // 选择模式，single、multiple、range
  mode:'single',

  // 当前选中的值
  // mode=single时 "String,Number,Object"
  // mode=multiple时"Array"
  // mode=range时"Array"，[开始时间,结束时间]
  value:null,

  // 默认日期，默认为今天
  // 视图会切换当前日期所在月
  defaultDate:null,

  // 载入日期数据信息
  /*[{
      date:'',
      info:'',
      bgColor:'', // 背景色
      color:'', // 日期文字颜色
      infoColor:'', // 信息文字颜色
      disabled:false, // 是否禁止选中
      opacity:null, // 替换disabled=true时的半透明状态
      badge:{isDot:false,bgColor:'',color:'',text:''}, // 徽标
  }]*/
  listInfo:[],

  // 显示农历
  lunar:false,

  // 最小日期
  minDate:null,
  // 最大日期
  maxDate:null,

  // 是否ios星期布局
  iosLayout:false,

  // 是否显示月份为背景
  showMonthBg:false,

  // 是否允许日期范围的起止时间为同一天，mode=range有效
  allowSelectSameDay:true,

  // 选中开始文字，mode=range有效
  selectStartText:'开始',

  // 选中结束文字，mode=range有效
  selectEndText:'结束',

  // 日期项样式配置
  /*{
    color:'c-t-main',
    infoColor:'c-t-regular',
    radius:'16rpx',

    // 今天背景色和文字颜色
    todayBgColor:'c-bg-primary-light-8',
    todayColor:'',

    // 选中项颜色和文字颜色
    activeBgColor:'c-bg-primary',
    activeColor:'#fff',
    // mode=range时，选中的背景和文字颜色
    activeRangeBgColor:'',
    activeRangeColor:''
  }*/
  itemStyle:{},
  // 星期样式
  /*{
    color:'c-t-main',
    fontSize:'28rpx',
    bgColor: '',
    height:'86rpx',
    bdr:true,
    bdrColor:'c-bdr-lighter',
  }*/
  weekStyle:{},

  // 插入模式，false时为弹窗模式
  insert:false,

  //以下配置在insert=false时有效
  // ================================
  // 标题
  // object请c-text组件的pros
  title:null,

  // 按钮位置是否在底部
  // 为true时，为按钮样式，false时为文字样式
  bottomBtn:false,

  // 按钮
  // object请c-text和c-btn组件的pros
  cancelText:'取消',
  confirmText:'确定',

  // 背景圆角
  radius:null,

  // 打开位置，top，center，bottom
  position:'bottom',

  // 点击遮罩关闭
  closeOnMaskClick: true,

  // 弹出层背景
  maskBgColor:'rgba(0, 0, 0, 0.4)',

  // 开启动画
  animation: true,
}