export default {
// 选择器名
    // 用于事件中返回
    name:null,

    // 默认选中的值
    value:null,

    // 列数据
    // mode=single 数据格式 ['标题1','标题二']
    // mode=multi 数据格式 [['标题1','标题二'],...]
    // mode=tree 数据格式 [{label:'标题',value:1,children:[]},...]
    columns:[],

    // mode=tree时，显示的层级
    // 不填时默认从第一条数据第一个children中计算
    treeLevel:null,

    // 标题
    // object请c-text组件的pros
    title:null,

    // 行高度
    itemHeight:'88rpx',
    // 显示的行数量
    itemRowCount:5,

    // 显示默认项
    prompt:null,

    // 列数据字段名设置
    labelName:'label',
    valueName:'value',
    childrenName:'children',

    // 按钮位置是否在底部
    // 为true时，为按钮样式，false时为文字样式
    bottomBtn:false,

    // 按钮
    // object请c-text和c-btn组件的pros
    cancelText:'取消',
    confirmText:'确定',

    // 圆角
    radius:null,

    // 打开位置，top，center，bottom
    position:'bottom',

    // 点击遮罩关闭
    closeOnMaskClick: true,

    // 弹出层背景
    maskBgColor:'rgba(0, 0, 0, 0.4)',

    // 开启动画
    animation: true,
}