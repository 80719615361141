import forEach from "./forEach";
import isEmpty from "./isEmpty";

/**
 * 简易封装 Promise.allSettled 用以支持对象
 * @param promiseList
 * @returns {Promise<unknown>}
 */
function promiseAll(promiseList = []){
    return new Promise((resolve) => {
        if(isEmpty(promiseList)){
            return resolve({},true);
        }
        const listMap = {};
        const newList = [];
        let index = 0;
        forEach(promiseList,function (n,key){
            listMap[index] = key;
            newList.push(n);
            index++;
        });
        newList.map(item => item.catch(err=>err))
        Promise.allSettled(newList).then(res=>{
            const newRes = {};
            let errCount = 0;
            forEach(res,(n,i)=>{
                if(n.status === 'fulfilled'){
                    n.status = true;
                }else{
                    n.status = false;
                    errCount++;
                }
                newRes[listMap[i]] = n;
            });
            resolve(newRes,errCount !== newList.length)
        })
    })
}
export default promiseAll