import toRpx from "./toRpx";
import isEmpty from "./isEmpty";

/**
 * css简写处理
 * @param val
 * @param cssAttr padding,margin,borderRadius
 * @returns {{}}
 */
function cssAttrSimplify(val, cssAttr){
  if(isEmpty(val)) return {};
  const _style = {};

  // padding,margin,borderRadius
  if(typeof val === 'number'){
    val = [val];
  }else if(typeof val === 'string'){
    val = val.split(' ');
  }

  switch (val.length){
    case 1:
      val[0] = toRpx(val[0]);
      if(cssAttr === 'borderRadius'){
        _style.borderTopLeftRadius = val[0];
        _style.borderBottomRightRadius = val[0];
        _style.borderTopRightRadius = val[0];
        _style.borderBottomLeftRadius = val[0];
      }else{
        _style[cssAttr+'Top'] = val[0];
        _style[cssAttr+'Right'] = val[0];
        _style[cssAttr+'Bottom'] = val[0];
        _style[cssAttr+'Left'] = val[0];
      }
      break;
    case 2:
      if(cssAttr === 'borderRadius'){
        _style.borderTopLeftRadius = toRpx(val[0]);
        _style.borderBottomRightRadius = _style.borderTopLeftRadius;
        _style.borderTopRightRadius = toRpx(val[1]);
        _style.borderBottomLeftRadius = _style.borderTopRightRadius;
      }else{
        _style[cssAttr+'Top'] = toRpx(val[0]);
        _style[cssAttr+'Bottom'] = _style[cssAttr+'Top'];
        _style[cssAttr+'Left'] = toRpx(val[1]);
        _style[cssAttr+'Right'] = _style[cssAttr+'Left'];
      }
      break;
    case 3:
      if(cssAttr === 'borderRadius'){
        _style.borderTopLeftRadius = toRpx(val[0]);
        _style.borderTopRightRadius = toRpx(val[1]);
        _style.borderBottomRightRadius = toRpx(val[2])
        _style.borderBottomLeftRadius = _style.borderTopRightRadius;
      }else{
        _style[cssAttr+'Top'] = toRpx(val[0]);
        _style[cssAttr+'Left'] = toRpx(val[1]);
        _style[cssAttr+'Right'] = _style[cssAttr+'Left'];
        _style[cssAttr+'Bottom'] = toRpx(val[2]);
      }
      break;
    default:
      if(cssAttr === 'borderRadius'){
        _style.borderTopLeftRadius = toRpx(val[0]);
        _style.borderTopRightRadius = toRpx(val[1]);
        _style.borderBottomRightRadius = toRpx(val[2]);
        _style.borderBottomLeftRadius = toRpx(val[3]);
      }else{
        _style[cssAttr+'Top'] = toRpx(val[0]);
        _style[cssAttr+'Right'] = toRpx(val[1]);
        _style[cssAttr+'Bottom'] = toRpx(val[2]);
        _style[cssAttr+'Left'] = toRpx(val[3]);
      }
      break;
  }

  return _style
}

export default cssAttrSimplify