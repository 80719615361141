export default {
    // 按钮文字
    // 为Object时参考c-text的props
    text: null,

    // 按钮类型
    // 支持scss $colors中扩展的主题
    // type=none，无样式
    type: null,

    // 是否为纯文字按钮
    plaintext: false,

    // 纯文字按钮的背景
    plaintextBg: false,

    // 按钮尺寸，base,sm,lg
    // 支持scss $btn-size中扩展的尺寸
    size: 'base',

    // 文本大小
    fontSize:null,

    // 文本颜色
    // 支持c-t-开头的类
    textColor:null,

    // 按钮是否镂空
    plain: false,
    // 是否禁止状态
    disabled: false,

    // 宽
    width: null,

    // 高
    height: null,

    // 圆角，radius=round时为圆形
    radius: null,

    // 边框宽度
    borderWidth:null,

    // 前后置icon
    icon: null,
    afterIcon: null,

    // 是否显示loading
    loading: false,

    // loading指示器，详细参考c-loading-indicator的props
    loadingIndicatorProps: {},

    // loading文本
    loadingText: null,

    // 是否竖向布局
    flexCol:false,

    // 路由跳转
    // 参考uni.$c.route()参数
    route: null,

    // 节流时间
    throttleTime:30,

    // 自定义按钮颜色的色值
    // - 使用后 plain，plaintext属性将失效
    // - 支持linear-gradient(to bottom right,#AD18F9,#05DFC7)渐变色，只支持两种颜色的渐变
    color: null,

    // 开启tag
    // 开启后将不具备原生button的功能，也无active效果
    tag:false,

    // 标识
    name:null,

    // 以下参考 https://uniapp.dcloud.net.cn/component/button.html
    formType: null,
    openType: null,

    hoverClass: null,
    hoverStartTime: 20,
    hoverStayTime: 70,
    appParameter: null,
    hoverStopPropagation:false,
    lang: 'zh_CN',
    sessionFrom: null,
    sendMessageTitle: null,
    sendMessagePath: null,
    sendMessageImg: null,
    showMessageCard: false,
    groupId: null,
    guildId: null,
    publicId: null,
    dataImId: null,
    dataImType: null,
    dataGoodsId: null,
    dataOrderId: null,
    dataBizLine: null
}