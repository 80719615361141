export default {
    name:null,

    // 标题
    title:null,

    // 标题配置
    // 详细参考c-text的props
    titleProps:null,

    // 是否显示标题下分割线
    titleDivider:false,

    // 显示按钮分割线，webStyle=false有效
    btnDivider:true,

    // 分割线配置，参考c-divider的props
    dividerProps:{
        borderColor:'c-bdr-light'
    },

    // 内容
    // 为array时，参考c-text的props
    msg:null,

    // msg的默认配置
    // 参考c-text的props
    msgPros:{},

    // 宽度
    width:'600rpx',

    // 是否web风格的对话框
    webStyle:false,

    // 显示icon
    // object请c-icon组件的pros
    icon:null,

    // icon位置，left、top、title
    iconPosition:'top',

    // 自定义按钮
    // 为true时：['取消','确认']，可通过btnReverse调换功能位置
    // 为false时，不显示按钮，只显示一个按钮如下(btnReverse=true略差)：
    //  - 隐藏“取消”按钮：[null,'确认']、[false,true]、['',true]
    //  - 隐藏“确认”按钮：[true,null]，['取消',null],['取消']
    // 数组项为Object参考 c-btn的props，例如：[{text:'取消',type:'success'}]
    btn:false,

    // 反转取消和确认按钮功能位置
    // 影响confirm和cancel事件
    btnReverse:false,

    // 按钮排列
    // webStyle=false值：row，column，默认row
    // webStyle=true：left、center、right，默认right
    btnAlign:null,

    // 为prompt时的输入框组件类型
    // 可选值 input，textarea
    controlType:'input',

    // 为prompt时的输入框组件配置
    controlProps:null,

    // 圆角
    radius:'16rpx',

    // 弹出层背景
    maskBgColor: 'rgba(0, 0, 0, 0.4)',

    // 开启动画
    animation: true,
}