import img from './props/img'
import text from "./props/text";
import loadingIndicator from "./props/loadingIndicator";
import loadMore from "./props/loadMore";
import icon from "./props/icon";
import navBar from "./props/navBar"
import divider from "./props/divider"
import transition from "./props/transition"
import btn from "./props/btn";
import box from "./props/box";
import alert from "./props/alert";
import toast from "./props/toast";
import popup from "./props/popup";
import empty from "./props/empty";
import page from "./props/page";
import loadingPage from "./props/loadingPage";
import list from "./props/list";
import listItem from "./props/listItem";
import badge from "./props/badge";
import tab from "./props/tab";
import picker from "./props/picker";
import form from "./props/form";
import formItem from "./props/formItem";
import input from "./props/input";
import textarea from "./props/textarea";
import search from "./props/search";
import checkboxGroup from "./props/checkboxGroup";
import checkbox from "./props/checkbox";
import dialog from "./props/dialog";
import calendar from "./props/calendar";
import datetimePicker from "./props/datetimePicker";
import numberBox from "./props/numberBox";
import upload from "./props/upload";
import actionSheet from "./props/actionSheet";
import html from "./props/html";
import cSwitch from "./props/switch";
import progress from "./props/progress";

export default {
    // 设计稿宽度
    designWidth:375,

    // 内置页面，需要在pages.json中配置页面，且开启uni-app模式
    // 视频预览页面地址，c-upload的视频预览
    videoPreviewPage:'/uni_modules/cook-uni/pages/video',
    // webview页面，微信小程序下实现$c.route跳转外部链接
    webviewPage:'/uni_modules/cook-uni/pages/webview',

    // 请求
    http:{
        //baseURL: '',
        // 配置参考libs/luch-request/readme.md文件
    },

    // 组件
    text,
    img,
    loadingIndicator,
    loadMore,
    icon,
    navBar,
    divider,
    transition,
    btn,
    box,
    alert,
    toast,
    popup,
    empty,
    page,
    loadingPage,
    list,
    listItem,
    badge,
    tab,
    picker,
    form,
    formItem,
    input,
    textarea,
    search,
    checkboxGroup,
    checkbox,
    dialog,
    numberBox,
    upload,
    html,
    actionSheet,
    switch:cSwitch,
    progress,
    calendar,
    datetimePicker,
}