import isEmpty from "./isEmpty";

/**
 * 设置值
 * @param obj
 * @param key {String} 用“.”分隔
 * @param value
 * @returns {*}
 */
function setObjValue(obj, key, value) {
    if (isEmpty(obj)) return;
    // 递归赋值
    const inFn = function(_obj, keys, v) {
        // 最后一个属性key
        if (keys.length === 1) {
            _obj[keys[0]] = v
            return
        }
        // 0~length-1个key
        while (keys.length > 1) {
            const k = keys[0]
            if (!_obj[k] || (typeof _obj[k] !== 'object')) {
                _obj[k] = {}
            }
            const key = keys.shift()
            // 自调用判断是否存在属性，不存在则自动创建对象
            inFn(_obj[k], keys, v)
        }
    }
    if (typeof key !== 'string' || key === '') {
    } else if (key.indexOf('.') !== -1) { // 支持多层级赋值操作
        const keys = key.split('.')
        inFn(obj, keys, value)
    } else {
        obj[key] = value
    }
}

export default setObjValue