export default {
    // 控制组件显示或隐藏
    show: false,
    // 过渡动画类型
    // fade 渐隐渐出过渡
    // slide-top 由上至下过渡
    // slide-right 由右至左过渡
    // slide-bottom 由下至上过渡
    // slide-left 由左至右过渡
    // zoom-in 由小到大过渡
    // zoom-out 由大到小过渡
    mode: 'fade',
    // 过渡动画持续时间
    duration: 300,
    // 使用的动画过渡函数
    timingFunction:'ease',

    // 只渲染一次
    onceRender:false,
}