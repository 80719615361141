export default {
  modelValue: null,

  // 最小值
  min: 0,

  // 最大值
  max: Number.MAX_SAFE_INTEGER,
  // 步长
  step: 1,

  // 允许的小数点长度
  decimal:null,

  // 禁用
  disabled: null,

  // 只读
  readonly:null,

  // 隐藏按钮
  hideBtn:false,

  // 减按钮
  minusBtnProps:{},

  // 加按钮
  addBtnProps:{},

  // 按钮样式配置
  // 参考c-btn的props
  btnProps:{},

  // 输入框样式配置
  // 参考c-input的props
  inputProps:{},
}