/**
 * 转换JSONArray为指定列的对象
 * @constructor
 */
function resetJSONArrKeyByCol(arrayData, fieldName){
    if (!arrayData) arrayData = [];
    let res = {};
    arrayData.forEach((n,i)=>{
        if (typeof n[fieldName] !== 'undefined') {
            res[n[fieldName]] = n;
        }
    });
    return res;
}

export default resetJSONArrKeyByCol