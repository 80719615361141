export default {
    src:null,

    width:null,

    height:null,

    // 裁剪模式，新增contain和cover，等同于aspectFit和aspectFill
    mode:null,

    // 圆角，radius=round时为圆形
    radius:null,

    // 自动补齐域名
    // 注意：“/static/”开头的本地地址不会补全
    // domain={指定生效平台}，多个“,”分隔，=true时等同于all指全部平台
    // domain=!{指定生效平台}，只能指定一个非平台
    // 平台名：vue3、vue2、plus、nvue、h5、weixin、alipay、baidu、toutiao、qq、
    // kuaishou、360、mp、webview、webview-union、webview-huawei
    domain:true,

    // 图片地址补全的默认Url
    baseUrl:null,

    // 是否为本地资源远程托管
    // remote={指定生效平台}，多个“,”分隔，=true时等同于mp，=all时指全部平台
    // remote=!{指定生效平台}，只能指定一个非平台
    // 平台名：vue3、vue2、plus、nvue、h5、weixin、alipay、baidu、toutiao、qq、
    // kuaishou、360、mp、webview、webview-union、webview-huawei
    remote:false,
    // 本地资源远程托管所在的Url
    // 为空时，默认使用baseUrl
    remoteUrl:null,

    // 默认图片
    // 输入defaultUrl的键名，值为true时表示第一个键名
    defaultImg:true,
    // 默认图片地址
    defaultImgUrl:null,

    // 默认图片裁剪模式
    defaultImgMode:'aspectFill',

    // src值为json格式时取值
    srcKey:'file',

    // 缩略图
    // 格式：w/{宽}/h/{高}/q/{0~100}/m/{模式}
    thumb:null,

    // 显示加载中图标
    // object时参考c-load-more组件的props
    showLoading:false,

    // 缩略图生成规则名
    // 目前只支持 aliyun（阿里云），更多规则扩展：
    // Vue.use(cookUni, {
    //    uriThumbRuleExtra:{
    //      规则名：规则方法
    //    }
    // })
    thumbRule:'aliyun',

    // 以下参考 https://uniapp.dcloud.net.cn/component/image.html
    lazyLoad:false,
    fadeShow:true,
    webp:false,
    showMenuByLongpress:false,
    draggable:false,
}