export default {
    // primary,error,warning,success,info
    type: 'danger',

    //是否是圆点
    isDot: false,

    // 显示的数值内容
    num: null,

    // 文字颜色
    color:null,

    // 数字显示的格式，overflow、ellipsis 、abbr
    // abbr， 需要 npm install numeral --save
    // http://numeraljs.com/
    // main.js中 挂载 CookUni({numeral})
    numType:'overflow',

    // 显示的最大值
    maxNum:99,

    // 当数值为 0 时，是否展示 Badge
    showZero: false,

    // 形状左下角为直角
    bgIsHorn:false,

    // 无背景的纯文字
    plaintext: false,

    // 组件是否绝对定位，为true时，offset参数才有效
    abs:false,

    // 位置偏移，格式为 [top, right]
    // 为string时，用“,”号分隔：“top,right”
    offset:null
}