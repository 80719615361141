import config from "./config/index"
import setConfig from "./utils/setConfig";
import Request from "./libs/luch-request";
import test from "./utils/test";
import forEach from "./utils/forEach";
import isPlainObject from "./utils/isPlainObject";
import isEmpty from "./utils/isEmpty";
import trim from "./utils/trim";
import stringToArray from "./utils/stringToArray";
import upperFirst from "./utils/upperFirst";
import lowerFirst from "./utils/lowerFirst";
import splitString from "./utils/splitString";
import camelCase from "./utils/camelCase";
import toNumber from "./utils/toNumber";
import toString from "./utils/toString";
import clone from "./utils/clone";
import merge from "./utils/merge";
import randomArray from "./utils/randomArray";
import arrayChunk from "./utils/arrayChunk";
import mathAdd from "./utils/mathAdd";
import mathDivide from "./utils/mathDivide";
import mathMultiply from "./utils/mathMultiply";
import mathRandom from "./utils/mathRandom";
import mathSubtract from "./utils/mathSubtract";
import cssAttrSimplify from "./utils/cssAttrSimplify";
import toast from "./utils/toast";
import toPx from "./utils/toPx";
import toRpx from "./utils/toRpx";
import uid from "./utils/uid";
import debounce from "./utils/debounce";
import throttle from "./utils/throttle";
import uriFilter from "./utils/uriFilter";
import platform from "./utils/platform";
import getObjValue from "./utils/getObjValue";
import route from "./utils/route";
import toUrlParams from "./utils/toUrlParams";
import {sys,getWindowHeight,os} from "./utils/sys";
import getPages from "./utils/getPages";
import getParent from "./utils/getParent";
import promiseAll from "./utils/promiseAll";
import getJSONArrCol from "./utils/getJSONArrCol";
import resetJSONArrKeyByCol from "./utils/resetJSONArrKeyByCol";
import getGroupByJSONArrCol from "./utils/getGroupByJSONArrCol";
import setObjValue from "./utils/setObjValue";

// 阿里云缩略图生成规则
function uriAliyunImgThumb(src,thumbConf){
  if (src.indexOf('aliyuncs.com') !== -1 && src.indexOf('x-oss-process=') === -1) {
    let _thumb = [];
    if (thumbConf.w !== 'auto') _thumb.push('w_' + thumbConf.w);
    if (thumbConf.h !== 'auto') _thumb.push('h_' + thumbConf.h);
    if (thumbConf.m) _thumb.push('m_' + thumbConf.m);
    if (thumbConf.m) _thumb.push('quality,q_' + thumbConf.q);
    return src + '?x-oss-process=image/resize,' + _thumb.join(',');
  }
  return src
}

const $c = {
  config,
  setConfig,
  http: new Request(),
  test,
  forEach,
  isPlainObject,
  isEmpty,
  trim,
  stringToArray,
  upperFirst,
  lowerFirst,
  splitString,
  camelCase,
  toNumber,
  toString,
  clone,
  merge,
  randomArray,
  arrayChunk,
  mathAdd,
  mathDivide,
  mathMultiply,
  mathRandom,
  mathSubtract,
  cssAttrSimplify,
  toast,
  toPx,
  toRpx,
  uid,
  debounce,
  throttle,
  uriFilter,
  platform,
  getObjValue,
  route,
  toUrlParams,
  sys,
  getWindowHeight,
  os,
  getPages,
  $parent:getParent,
  promiseAll,
  getJSONArrCol,
  resetJSONArrKeyByCol,
  getGroupByJSONArrCol,
  setObjValue,

  // 图片扩展
  uriThumbRuleExtra:{
    aliyun:uriAliyunImgThumb,
  }
};

// $c挂载到uni对象上
uni.$c = $c;

function install(app,options = {}) {
  forEach(options,(v,k)=>{
    if(k === 'config'){
      forEach(v,(v,k)=>{
        setConfig(k,v)
      })
    }else{
      $c[k] = v;
    }
  });

  // #ifndef APP-NVUE
  // #endif
  app.config.globalProperties.$c = $c
};

export default install