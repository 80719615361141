export default {
// 百分比0~100
  percentage:null,

  // 百分比小数点长度
  decimalLen:0,

  // 未选中时的背景颜色
  // 支持c-bg-开头的类
  color:null,

  // 选中时颜色
  // 支持c-bg-开头的类
  activeColor:null,

  // 显示文本
  // 未object时参考c-text的props
  showText:false,

  // 文本是在内部显示
  innerText:false,

  // 高度
  size:'12rpx',

  // 圆角，radius=round时为圆形
  radius: 'round',

  // 动画时长
  duration:300
}