import isObject from "./_base/isObject";

/**
 * 深度克隆
 * @param obj
 * @return {[]|{}|*}
 */
function clone (obj) {
	// 对常见的“非”值，直接返回原来值
	if([null, undefined, NaN, false].includes(obj)) return obj;


    if(!isObject(obj)) {
		//原始类型直接返回
        return obj;
    }
    var o = Array.isArray(obj) ? [] : {};
    for(let i in obj) {
        if(obj.hasOwnProperty(i)){
            o[i] = isObject(obj[i]) ? clone(obj[i]) : obj[i];
        }
    }
    return o;
}

export default clone;
