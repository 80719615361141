export default {
  // 用于渲染的 html 字符串
  content: '',

  // 容器内容的样式
  containerStyle: '',

  // 标签的默认样式
  // 格式为 标签名: 样式
  //{
  //    a: 'color:red' // a 标签默认为红色
  //}
  // 该属性非响应式，需要在设置 content 属性前设置才能生效，动态修改不能实时生效
  // 该属性的原理是解析到各标签的内联 style 属性中去，如果对特别常用的标签设置默认样式，将大大加大解析结果大小，减慢渲染速度，这种情况下建议通过 外部样式 引入
  // https://jin-yufeng.gitee.io/mp-html/#/overview/quickstart?id=setting
  tagStyle: {},

  // 是否允许外部链接被点击时自动复制
  // 对于 uni-app 的 h5 和 app 平台，外链是能够直接跳转的，这种情况下如果该属性为 true 则直接跳转外链（而不是复制链接），为 false 则不跳转
  copyLink: true,

  // 主域名，用于拼接链接
  // 该属性必须填写 协议名://域名 的完整链接
  // 暂不支持拼接含有 ../ 的相对路径链接
  // a 标签的 href 属性可能需要跳转到小程序内路径，因此不进行 domain 拼接
  // 设置该属性后将无法使用本地图片
  domain: '',

  // 图片出错时的占位图链接
  // 该属性不会进行拼接 domain，需传入完整路径（可以使用本地路径）
  errorImg: '',

  // 图片加载过程中的占位图链接
  // 该属性不会进行拼接 domain，需传入完整路径（可以使用本地路径）
  loadingImg: '',

  // 是否开启图片懒加载
  // 不同平台懒加载的时机不同，具体参考各平台 image 组件懒加载的时机
  lazyLoad: true,

  // 是否在播放一个视频时自动暂停其他视频
  // 如果需要多个视频同时播放的，请将此属性设置为 false
  pauseVideo: true,

  // 是否允许图片被点击时自动预览
  // 自动预览允许左右滑动查看所有图片，如果不希望如此可以禁用自动预览并在 imgtap 事件中自行处理
  previewImg: true,

  // 是否给每个表格添加一个滚动层使其能单独横向滚动
  // 如果表格设置了 inline 布局，该属性将不会生效以免破坏行内布局
  scrollTable: false,

  // 是否开启长按复制，开启后可能出现：
  // 1、所有文本块会显示为 inline-block（通过 text 标签的 user-select 属性实现），需要自行适配
  // 2、文字下划线、删除线等效果将失效
  selectable: false,

  // 标签的内容设置到页面标题
  setTitle: true,

  // 是否允许图片被长按时显示菜单
  // 微信、百度和 uni-app 的 app 平台有效
  showImgMenu:true,

  // 是否使用锚点链接
  // 传入一个数字时表示跳转锚点的偏移量（单位 px）
  // 开启该属性会将所有设置了 id 属性的标签都暴露出来，一定程度上减慢渲染速度，非必要不要开启
  useAnchor: false
}