/**
 * 随机一个数
 * @param min
 * @param max
 * @returns {number}
 */
function mathRandom(min, max) {
	if (min >= 0 && max > 0 && max >= min) {
		let gab = max - min + 1;
		return Math.floor(Math.random() * gab + min);
	} else {
		return 0;
	}
}

export default mathRandom;
