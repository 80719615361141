export default {
    width:'34rpx',
    height:'34rpx',

    // 值不为auto时，为list组件下拉距离
    play:'auto',
    // play=auto时播放速度，单位ms，默认值25|非图片720
    speed:null,

    // play!=auto时，用于计算帧、位置和动画播放速度，值为list组件的downOption.offset值
    threshold:45,

    // 指示器类型
    // 可选的值 semicircle、snowflake 和 帧序列图片
    // 为object时为帧序列图片，格式遵循 uni.$c.uriFilter() 的配置：
    //{
    //    src:'',
    //    domain:true,
    //    baseUrl:null,
    //    remote:false,
    //    remoteUrl:null,
    //    defaultImg:true,
    //    defaultImgUrl:null,
    //    thumb:null,
    //    thumbRule:null,
    //    srcKey:'file'
    //}
    type:'semicircle',

    // 指示器颜色，img=false不存在时有效
    // 支持c-bdr-{主题}
    color:'#666666',

    // 以下配置在img存在时有效，且必填

    // 图片宽高，单位px
    imgWidth:null,
    imgHeight:null,

    // 帧数
    frameCount:null,

    // 帧宽高，单位px
    frameWidth:null,
    frameHeight:null,

    // 是否循环播放
    loop:true,
}