export default {
    // 导航栏标题
    // 为Object时参考c-text的props
    title: null,

    // 左边返回图标的名
    // 为object时，请参考c-icon的props
    leftIcon:'back',

    // 左边的提示文字
    // 为Object时参考c-text的props
    leftText:null,

    // 标题、文字和icon的统一默认颜色
    // 支持c-t开头的颜色类
    color:'c-t-main',

    // 导航栏是否固定在顶部
    fixed: false,

    // 固定在顶部时，是否生成一个等高元素，以防止塌陷
    placeholder:true,

    // 导航栏标题的最大宽度，内容超出会以省略号隐藏
    titleWidth:'470rpx',

    // 状态栏颜色
    // 默认和背景色保持一致
    statusBarBgColor:null,

    // 自定义返回事件
    customBackEvent:false,

    // 返回主页icon
    // 只有getCurrentPages只有当前一个页面，且不为首页时显示
    // 配置请参考c-icon的props
    homeIcon:'home',

    // 隐藏首页icon
    // 如果当前页是tab页，这里设置为true
    hideHomeIcon:false,

    // 首页的路由配置
    // 主要用于小程序下，从分享页面进入时，进入首页
    // 格式参考 uni.$c.route
    homeRoute:{},

    // 是否为非页面级导航
    // 如果是用在弹出层等，设为true
    // true时奖不会判断leftIcon和homeIcon的显示，且fixed下位绝对定位
    isPageNav:false
}