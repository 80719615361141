export default {
    // 当前选中标签的索引
    current:0,

    //标签数组，格式：
    /*[
        {
            name:'标签一',
            itemStyle:{},
            itemActiveStyle:{},
            textStyle:{},
            textActiveStyle:{},
            badge:{}, // 显示徽章
            img:{}, // 显示图片
            activeImg:{}, // 显示图片
            icon:{}, // 显示图标
            activeIcon:{}, // 显示图标
        }
    ]*/
    list:[],

    // 是否垂直类型
    vertical: false,

    // 高度
    // vertical=true时，height=null会自动计算
    // 注意：小程序下需要给组件加一个 style="flex:1"
    height:null,

    // 宽度
    // vertical=false时，width=null会自动计算
    width:null,

    // 指示器样式
    // 参考c-box的props
    // =false时不显示指示器
    indicatorStyle:{},

    // 开启指示器动画
    indicatorAnimation:true,

    // tab项样式
    // 注意：不要使用外边距margin
    itemStyle:{},

    // tab项选中样式
    itemActiveStyle:{},

    // tab项文本样式
    // 参考c-text的props
    textStyle:{},

    // tab项文本选中样式
    // 参考c-text的props
    textActiveStyle:{},

    // 滚动条左右内边距，
    // 可以用“,”逗号分隔，数组表示左右
    scrollCntGutter:null,

    // 显示分割线
    divider:false,

    // 分割线配置
    dividerProps:{},

    // tab项不足时是否居中排列
    lessCenter:true,

    // 从list元素对象中读取的键名
    keyName:'name',
}