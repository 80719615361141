import mathMultiply from "./mathMultiply"
/**
 * 加
 *
 * @since 3.4.0
 * @category Math
 * @param {number} augend The first number in an addition.
 * @param {number} addend The second number in an addition.
 * @returns {number} Returns the total.
 * @example
 *
 * add(6, 4)
 * // => 10
 */
function mathAdd(augend, addend) {
  let c, d, e;
  try {
    c = augend.toString().split(".")[1].length;
  } catch (f) {
    c = 0;
  }
  try {
    d = addend.toString().split(".")[1].length;
  } catch (f) {
    d = 0;
  }
  return e = Math.pow(10, Math.max(c, d)), (mathMultiply(augend, e) + mathMultiply(addend, e)) / e;
}

export default mathAdd