// 页面路径：store/index.js
import { createStore } from 'vuex'
const store = createStore({
  state:{
    // 登录信息
    authToken:null,
    // 用户资料
    userInfo:null,
  },

  mutations:{
    setAuthToken(state,data){
      state.authToken = data;
    },
    setUserInfo(state,data){
      state.userInfo = data;
    },
  },
  actions:{
    login({ commit },data){
      let token = data?data:null;
      if(token){
        uni.setStorage({
          key: 'USER_AUTH',
          data: token
        });
      }else{
        token = uni.getStorageSync('USER_AUTH');
      }
      if(token){
        commit('setAuthToken',token);
        uni.$c.api.getUserProfile().then(res=>{
          commit('setUserInfo',res);
        })
      }
    },
    logout({ commit },data){
      commit('setAuthToken',null);
      uni.removeStorage({
        key: 'USER_AUTH',
      })
    }
  }
})

export default store