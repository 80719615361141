/**
 * 对JSONArray按指定列进行分组
 * @constructor
 */
function getGroupByJSONArrCol(arrayData, fieldName){
    if (!arrayData) arrayData = [];
    let res = {};
    arrayData.forEach((n,i)=>{
        if (typeof res[n[fieldName]] === 'undefined') res[n[fieldName]] = [];
        res[n[fieldName]].push(n);
    });
    return res;
}

export default getGroupByJSONArrCol