/**
 * toast
 * @param title
 * @param duration
 */
function toast(title, duration = 2000) {
  uni.showToast({
    title: String(title),
    icon: 'none',
    duration
  })
  return new Promise(resolve => {
    setTimeout(function (){
      resolve();
    },duration+20)
  })
}
export default toast;