export default {
    // 表单域的属性名，在使用校验规则时必填
    prop:'',

    // 输入框左边的文字提示
    label:'',
    // label样式
    // 参考c-text的props
    labelPros:null,

    // label 右边显示红色"*"号，样式显示不会对校验规则产生效果
    required:false,

    // label 位置，left，top
    labelPosition:null,
    // label 宽度
    // 值可以是auto
    labelWidth:null,
    // label 居中方式，left/center/right
    labelAlign:null,
    // label和内容之间的间距
    labelGutter:null,

    // 是否显示表单域的边框
    // none,top,bottom
    border:null,
    // 边框样式
    // 参考c-divider的props
    borderStyle:null
}