import forEach from "./forEach";
import isPlainObject from "./isPlainObject";

/**
 * 对象深度合并
 * @return {{}}
 */
function merge(/* obj1, obj2, obj3, ... */) {
	let result = {}
	function assignValue(val, key) {
		if (isPlainObject(result[key]) && isPlainObject(val)) {
			result[key] = merge(result[key], val)
		} else if (isPlainObject(val)) {
			result[key] = merge({}, val)
		} else {
			result[key] = val
		}
	}
	for (let i = 0, l = arguments.length; i < l; i++) {
		forEach(arguments[i], assignValue)
	}
	return result
}

export default merge;