export default {
  // 标题
  title:null,
  // 描述
  desc:null,

  // 按钮列表
  // 参考c-btn的props，格式:[{text:'操作1'}]
  list:[],

  // 取消按钮
  cancelBtn:true,


  // 圆角
  radius:null,

  // 点击遮罩关闭
  closeOnMaskClick: true,

  // 弹出层背景
  maskBgColor:'rgba(0, 0, 0, 0.4)',

  // 开启动画
  animation: true,
}