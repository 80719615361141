export default {

  // 数据格式
  // {name:'',url:''}
  modelValue:null,

  // 圆角尺寸
  // 支持c-radius-开头的类
  radius: 'c-radius-base',

  // 上传类型，image，video，media（image和video），all
  // app安卓端选择的视频最大只支持180MB
  // type=all，只支持 h5和微信小程序、qq小程序
  // app下选择全部文件，可以结合原生插件并实现uni.$c.config.upload.chooseFile()方法
  type:'image',

  // 自定义选择文件方法，返回一个promise
  // 跳过使用默认选择：reject(false)
  // 成功：resole([{fileType:'',name:'',size:0,tempFilePath:''}])
  chooseFile:null,

  // 列表风格，card，text，picText
  listStyle:'card',

  // 是否自动上传
  // =false时，可调佣本组件this.$refs.myUpload.upload()方法手动上传
  // =true时，需要实现 this.$refs.myUpload.uploadApi
  autoUpload:false,

  // 上传请求方法，返回一个promise
  // 失败：reject('失败信息')
  // 成功：resole({url:'',...})
  uploadApi:null,

  // 是否可编辑name
  editName:false,

  // 是否显示删除按钮
  delBtn:true,

  // 是否可预览
  preview:true,

  // 自定义预览文件方法，只在非图片下有效
  previewFun:null,

  // album 从相册选图，camera 使用相机，默认二者都有
  sourceType:['album','camera'],

  // 是否压缩
  compressed:true,

  // 可上传数量
  limit:9,

  // 调用的摄像头，'front'、'back'
  // 生效范围：1、type=video且在APP、微信小程序、京东小程序下 2、type=image的微信小程序、抖音小程序、飞书小程序、京东小程序
  camera:'back',

  // 文件扩展名过滤，h5下有效，或type=all时
  extension:null,

  // 视频最大录制时长，type=video时有效
  // APP、微信小程序、百度小程序、京东小程序
  // type=media时 微信小程序、抖音小程序、飞书小程序、京东小程序 ，视频时长只能是3~30秒。
  maxDuration:30,

  // 是否禁用
  disabled:null,

  //是否展示模式
  display:false,

  // 选择按钮位置，before，after
  chooseBtnPosition:'after',

  // 选择按钮样式
  // 参考c-btn的props
  chooseBtnProps:{},

  // 列表项尺寸
  // listStyle=card时为160rpx，listStyle=picText时为80rpx
  itemSize:null,

  // 列表项间隙
  itemGutter:'16rpx',

  // 列表项边框颜色
  // 支持c-bdr-开头的类
  itemBdrColor:null,

  // 预览的图片设置
  // 参考c-img的props
  thumbProps:{},

  // 文件名样式
  // 参考c-text的props
  nameProps:{},
}