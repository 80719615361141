export default {
    // 类型text,date,number
    // type="date" 需要 npm install dayjs --save
    // https://day.nodejs.cn/docs/en/installation/installation
    // type="number" 需要 npm install numeral --save
    // http://numeraljs.com/
    // main.js中 挂载 CookUni({numeral,dayjs})
    type:'text',

    // 文本
    text:null,

    // 前置文本
    before:null,
    // 后置文本
    after:null,
    // 格式
    format:null,
    // 颜色
    // 支持 c-t-{主题}
    color: 'c-t-main',
    // 大小
    fontSize:'28rpx',
    // 行数，超出显示“...”
    line:null,
    // 行高
    lineHeight:null,
    // 加粗
    bold:false,
    // 对齐
    align:null,
    // 斜体
    italic:false,
    // 删除线
    delete:false,
    // 下划线
    underline:false,

    // 见https://uniapp.dcloud.net.cn/component/text.html
    selectable:false,
    userSelect:false,
    space:'',
    decode:true,
}