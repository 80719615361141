import asciiToArray from './_base/asciiToArray.js'
import hasUnicode from './_base/hasUnicode.js'
import unicodeToArray from './_base/unicodeToArray.js'

/**
 * 字符串赚为数组
 *
 * @private
 * @param {string} string The string to convert.
 * @returns {Array} Returns the converted array.
 */
function stringToArray(string) {
  return hasUnicode(string)
    ? unicodeToArray(string)
    : asciiToArray(string)
}

export default stringToArray
