import isPlainObject from "./isPlainObject";

/**
 * 是否为空
 * @return {[]|{}|*}
 * @param val
 */
function isEmpty (val) {
    return val === null ||
        val === '' ||
        typeof val === 'undefined' ||
        val === false ||
        (Array.isArray(val) && val.length === 0) ||
        (isPlainObject(val) && (Object.keys(val)).length === 0)
}

export default isEmpty;