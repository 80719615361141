function install(app) {
  const api = {};
  const http = app.config.globalProperties.$c.http;

  /**
   * 发送验证码
   * @param postData
   * @returns {*}
   */
  api.smsSend = function (postData){
    return http.post('/api/sms/sendCode',postData)
  }

  /**
   * 验证码登录
   * @param postData
   * @returns {*}
   */
  api.passportLoginCodeRegister = function (postData){
    return http.post('/api/passport/login/code/register', postData)
  }

  /**
   * 更新用户资料
   * @param postData
   * @returns {*}
   */
  api.updateUserProfile = function (postData){
    return http.post('/api/u/info/update', postData)
  }

  /**
   * 获取用户资料
   * @returns {*}
   */
  api.getUserProfile = function (){
    return http.get('/api/u/info')
  }

  /**
   * 成药匹配记录
   * @returns {*}
   */
  api.prescriptionLog = function (pageIndex,pageSize){
    return http.get('/api/u/patent/record/list',{
      params:{
        pageIndex:pageIndex,
        pageSize:pageSize,
      }
    })
  }
  api.prescriptionLogDel = function (id){
    return http.post(`/api/u/patent/record/delete/${id}`)
  }

  /**
   * 药物搜索
   * @param keyword
   * @param task
   * @returns {*}
   */
  api.medicineSearch = function (keyword,task){
    return http.get('/api/u/medicine/search',{
      params:{
        keyword:keyword
      },
      getTask: typeof task === 'function'?task:(task, options) => {}
    })
  }

  // 药方详情
  api.medicinePrescriptionDetail = function (id){
    return http.get(`/api/u/medicine/prescription/detail/${id}`)
  }

  /**
   * 查找成药
   * @param medicineList
   * @returns {*}
   */
  api.findPrescription = function (medicineList){
    return http.post(`/api/u/medicine/find-patent`,medicineList)
  }

  /**
   * 保存成药
   * @returns {*}
   */
  api.prescriptionSave = function (postData){
    return http.post('/api/u/patent/record/save',postData);
  }

  /**
   * 查询药详情
   * @param id
   * @returns {*}
   */
  api.medicineDetail = function (id){
    return http.get(`/api/u/medicine/detail/${id}`)
  }

  /**
   * 获取单页数据
   * @param type
   * @returns {*}
   */
  api.page = function (type){
    return http.get(`/api/system/content/${type}`)
  }

  /**
   * 查询问卷是否存在
   * @param mobile
   * @returns {*}
   */
  api.constitutionIsExist = function (mobile){
    return http.get(`/api/u/constitution/exist`,{params:{mobile:mobile}})
  }

  // 获取问卷公司名
  api.getConstitutionCompany = function (){
    return http.get('/api/u/constitution/company')
  }

  // 获取问卷题目列表
  api.constitutionQuestionList = function (){
    return http.get('/api/u/constitution/list')
  }
  // 保存问卷
  api.constitutionSave = function (postData){
    return http.post('/api/u/constitution/save',postData)
  }

  // 获取问卷详情
  api.getConstitutionDetail = function (id){
    return http.get(`/api/u/constitution/detail/${id}`)
  }

  // 问卷创建报告
  api.constitutionReportCreate = function (postData){
    return http.post('/api/u/constitution/report/create',postData)
  }

  app.config.globalProperties.$c.api = api;
}
export default install