import forEach from "./forEach";
import isEmpty from "./isEmpty";

/**
 * 获取简单对象值
 * @param obj
 * @param key 类似'a.b.c'的形式去获取一个对象的的属性的形式
 * @param defaultValue more值
 * @return {*}
 */
function getObjValue(obj, key, defaultValue){
    if(isEmpty(obj) || isEmpty(key)) return defaultValue;
    if(!Array.isArray(key)) key = String(key).split('.');
    let res = defaultValue;
    forEach(key, function(k, i) {
        if (typeof obj[k] === 'undefined') return false;
        if (i === key.length - 1) {
            res = obj[k];
        } else {
            res = getObjValue(obj[k],key.slice(i+1),defaultValue);
        }
        return false
    });
    return res;
}

export default getObjValue