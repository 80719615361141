export default {
    modelValue:null,

    // 是否单选
    radio:false,

    // 允许单选取消选择
    allowRadioCancel:false,

    // 横向布局
    layoutRow:false,

    // 标识
    name:null,


    // 选项框形状
    // none,square,circle,dot
    shape:null,

    // 是否按钮样式
    btn:null,
    // 按钮样式配置
    // 参考c-btn的props配置
    btnProps:null,
    activeBtnProps:null,

    // 选框颜色
    // 支持c-bg-开头的类
    color:null,
    activeColor:null,

    // 中边框色
    // 支持c-bdr-开头的类
    bdrColor:null,
    activeBdrColor:null,

    // icon颜色
    iconColor:null,
    activeIconColor:null,

    // icon尺寸
    iconSize:null,

    // 是否禁用
    disabled:null,

    //是否只读
    // 与disabled不同之处在于disabled会置灰组件，而readonly则不会
    readonly:null,

    // 禁用label点击选择
    disabledLabelClick:null,

    // label和选项框之间的距离
    labelGutter:null,

    // 标签样式
    // 参考c-text组件的props
    labelProps:null,

    // 选中标签样式
    // 参考c-text组件的props
    activeLabelProps:null,

    // label位置，right|left
    labelPosition:null,

    // label是否填充整行
    labelFill:null,
}