export default {
    bgColor:'#fff',

    // 标题
    title:null,

    // 加载中
    loading:false,

    // loading项配置
    // 参考c-loading-page组件的props
    loadingProps:{},

    // 自动高度
    autoHeight:false,

    // 开启页面滚动
    // autoHeight=false时有效
    scroll:false,

    // 是否显示底部安全距离
    safeBottom:true,

    // 底部安全距离背景色
    safeBottomBgColor:null,
}