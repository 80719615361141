import mathMultiply from "./mathMultiply"

/**
 * 除
 *
 * @since 4.7.0
 * @category Math
 * @param {number} dividend The first number in a division.
 * @param {number} divisor The second number in a division.
 * @returns {number} Returns the quotient.
 * @example
 *
 * divide(6, 4)
 * // => 1.5
 */
function mathDivide(dividend, divisor) {
  let c, d, e = 0,
    f = 0;
  try {
    e = dividend.toString().split(".")[1].length;
  } catch (g) {}
  try {
    f = divisor.toString().split(".")[1].length;
  } catch (g) {}
  return c = Number(dividend.toString().replace(".", "")), d = Number(divisor.toString().replace(".", "")), mathMultiply(c / d, Math.pow(10, f - e));
}

export default mathDivide
