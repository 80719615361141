/**
 * 数组分组
 * @param arr {Array}
 * @param size {Number}
 */
function arrayChunk(arr, size) {
  if (size < 2) return arr;
  return Array.apply(null, {
    length: Math.ceil(arr.length / size)
  }).map((x, i) => {
    return arr.slice(i * size, (i + 1) * size);
  })
}

export default arrayChunk