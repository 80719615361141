export default {
    // loadMore,loading,noMore,error,success
    status:'loading',

    // 状态文本
    loadMoreText:'加载更多',
    loadingText:'加载中...',
    noMoreText:'-- 没有更多了 --',
    errorText:'加载失败',
    successText:'加载成功',

    // 状态背景
    loadMoreBgColor: null,
    loadingBgColor: null,
    noMoreBgColor: null,
    successBgColor: null,
    errorBgColor: null,

    // 状态icon
    // 为Object参考c-icon的props
    loadMoreIcon:null,
    noMoreIcon:null,
    errorIcon:null,
    successIcon:null,
    // loading指示器，详细参考c-loading-indicator组件
    loadingIndicatorProps:{},

    // loading指示器位置 left,top
    indicatorPosition:'left',
    //loading指示器和文本距离
    indicatorGutter:'10rpx',

    // 文本配置，详情参考c-text的props
    textProps: {},

    // icon配置，详情参考c-icon的props
    iconProps: {},

    // 组件高度
    height:null,

    //指定按下去的样式类。当 hover-class="none" 时，没有点击态效果
    hoverClass:'none',
    // 指定是否阻止本节点的祖先节点出现点击态，微信小程序有效
    hoverStopPropagation: false,
    // 按住后多久出现点击态，单位毫秒
    hoverStartTime: 50,
    // 手指松开后点击态保留时间，单位毫秒
    hoverStayTime: 400,
}