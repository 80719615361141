/**
 * 获取系统信息
 *
 * 扩展了navBarHeight、safeBottomHeight属性
 * 其他属性参考：https://uniapp.dcloud.net.cn/api/system/info.html
 *
 * 注意：此方法首次运行会缓存数据，如果首次在有tabBar的页面会造成windowHeight的高度不正确
 * 获取windowHeight高度请使用uni.$c.getWindowHeight()方法
 *
 */
import mathAdd from "./mathAdd";

export function sys(noCache = false){
    const app = getApp({allowDefault: true});
    let systemInfo = app.globalData.cSystemInfo;
    if(!systemInfo || noCache){
        systemInfo = uni.getSystemInfoSync();

        // 非安全底部高度
        // #ifdef MP-WEIXIN
        systemInfo.safeBottomHeight = systemInfo.screenHeight - systemInfo.safeArea.bottom
        // #endif
        // #ifndef MP-WEIXIN
        systemInfo.safeBottomHeight = systemInfo.safeAreaInsets.bottom
        // #endif

        // 导航栏高度
        systemInfo.navBarHeight = 44;
        // #ifdef MP
        systemInfo.menuButton = uni.getMenuButtonBoundingClientRect();
        // 微信小程序/支付宝小程序/百度小程序/字节跳动小程序/飞书小程序/QQ小程序/360小程序
        //if(systemInfo.platform !== 'ios')  systemInfo.navBarHeight = 48;
        // #endif
        // #ifdef MP-ALIPAY
        systemInfo.navBarHeight = systemInfo.titleBarHeight;
        // #endif
        // #ifdef MP-BAIDU
        systemInfo.navBarHeight = systemInfo.navigationBarHeight;
        // #endif

        systemInfo.headerHeight = mathAdd(systemInfo.navBarHeight,systemInfo.statusBarHeight);

        app.globalData.cSystemInfo = systemInfo;
    }
    return systemInfo;
}

/**
 * 获取窗口高度
 */
export function getWindowHeight(){
    return uni.getSystemInfoSync().windowHeight;
}

/**
 * 平台信息
 * ios、android、mac、windows、linux
 * @returns {string}
 */
export function os(){
    return sys().osName.toLowerCase()
}