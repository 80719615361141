export default {
    // 图标名
    name: null,
    // 颜色
    // 支持c-t开头的类
    color: 'c-t-main',
    // 图标尺寸
    size:'32rpx',

    // 是否加粗
    bold:false,

    // icon资源名
    source:'cook-uni',
    // icon字符集和图标名的映射
    // 自定义图标时传入
    unicodeMap:{},
}