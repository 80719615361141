export default {
    // 显示
    show:false,

    // 背景色
    bgColor:'rgba(255,255,255,.8)',

    // loading配置
    // 参考c-load-more的props
    loadingProps:{}
}