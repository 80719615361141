
/**
 * 循环
 * 可循环 数组、对象、字符串、数字
 * 回调中return false跳出
 * @param collection {Array,Object,String,Number}
 * @param callback {Event} 三个参数 callback(value，key，count)
 * @return Array,Boolean
 */
function forEach(collection, callback) {
    if (!collection || typeof callback !== 'function') return false;

    let res = [];
    if (Array.isArray(collection)) {
        for (let i = 0; i < collection.length; i++) {
            let _r = callback(collection[i], i,i);
            if (_r === false) break;
            else if(_r) res.push(_r);
        }
    } else{
        const _type = typeof collection;
        if(_type === 'object') {
            let count = 0;
            for (let i in collection) {
                let _r = callback(collection[i], i,count);
                count ++;
                if (_r === false) break;
                else if (_r) res.push(_r);
            }
        }else if(_type === 'number'){
            for (let i = 0; i < collection; i++) {
                let _r = callback(i, i,i);
                if (_r === false) break;
                else if(_r) res.push(_r);
            }
        }else if(_type === 'string'){
            let i = 0;
            for (const value of collection) {
                let _r = callback(value, i,i);
                if (_r === false) break;
                else if(_r) res.push(_r);
                i++;
            }
        }else{
            res = false;
        }
    }
    return res?(res.length>0?res:null):false;
}

export default forEach