export default {
    modelValue:null,

    // 类型，H5暂未支持动态切换
    // text，password，number，digit，idcard，tel，safe-password，nickname
    type:'text',

    // 输出数字 type=number，digit 时有效
    toNumber:true,

    // 输入框高度
    // 注意此高度是原生input组件的高度，非当前组件的高度
    // 如果原生input组件的min-height大于设置的height，那么height无效
    height:null,

    // 水印
    placeholder:null,

    // 水印颜色
    // 支持c-t-开头的类(字节跳动系列小程序不支持)
    placeholderColor:'c-t-input-placeholder',

    // 是否禁用
    disabled:null,

    // 禁用背景色
    // 支持c-bg-的类
    disabledBgColor:'c-bg-base',

    //是否只读
    // 与disabled不同之处在于disabled会置灰组件，而readonly则不会
    readonly:null,

    // 是否自动获得焦点
    focus:false,

    // 前置图标
    // 为object时参考c-icon的props
    beforeIcon:null,

    // 后置图标
    // 为object时参考c-icon的props
    afterIcon:null,

    // 显示清除按钮
    clearBtn:true,

    // 清除图标
    // 参考c-icon的props
    clearIcon:'error-fill',

    // type=password 时，是否显示小眼睛图标
    showPasswordIcon:true,

    // 密码图标配置
    // 参考c-icon的props
    passwordIconProps:{},

    // 文字对齐方式，left | center | right
    align:'left',
    // 文字颜色，支持c-t-开头的类
    color:null,

    // 文字大小
    fontSize:null,

    // 边框
    // all,bottom,none
    border:'all',

    // 边框颜色
    // 支持c-bdr-开头的类
    borderColor:null,

    // 圆角
    radius:'c-radius-base',

    // 最大输入长度，设置为 -1 的时候不限制最大长度
    maxLength:-1,

    // 设置键盘右下角按钮的文字，仅在 type="text" 时生效。
    // search,go,send,done,next
    confirmType:'done',

    // 指定光标与键盘的距离
    // 取 input 距离底部的距离和 cursor-spacing 指定的距离的最小值作为光标与键盘的距离
    cursorSpacing:0,

    // 点击键盘右下角按钮时是否保持键盘不收起
    confirmHold:false,

    // 光标起始位置，自动聚集时有效，需与selection-end搭配使用
    selectionStart:-1,
    // 光标结束位置，自动聚集时有效，需与selection-start搭配使用
    selectionEnd:-1,
    // 键盘弹起时，是否自动上推页面
    adjustPosition:true,

    // 键盘收起时，是否自动失去焦点
    autoBlur:false,

    // 文本区域的语义，可选值 oneTimeCode
    // 仅 App-nvue-iOS 支持
    textContentType:'',

    // 以下微信小程序有效
    // 参考https://uniapp.dcloud.net.cn/component/input.html
    alwaysEmbed:false,
    holdKeyboard:false,
    safePasswordCertPath:null,
    safePasswordLength:null,
    safePasswordTimeStamp:null,
    safePasswordNonce:null,
    safePasswordSalt:null,
    safePasswordCustomHash:null,

    // 以下支付宝小程序有效
    // 参考https://uniapp.dcloud.net.cn/component/input.html
    randomNumber:false,
    controlled:false,
    alwaysSystem:false,
}