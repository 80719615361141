export default {
    // 弹出层类型
    // 可选值，top|center|bottom|left|right
    type: 'center',

    // 宽,type=left|center|right有效
    width:null,

    // 高,type=top|center|bottom有效
    height:null,

    // 距离顶部偏移
    topOffset:null,

    // 圆角
    radius:null,

    // 点击遮罩关闭
    closeOnMaskClick: true,

    // 背景色
    bgColor: '#fff',

    // 弹出层背景
    maskBgColor: 'rgba(0, 0, 0, 0.4)',

    // 开启动画
    animation: true,

    // 是否显示底部安全距离
    safeArea:true,

    // 底部安全距离背景色
    safeAreaBgColor:null,
}