/**
 * 资源地址过滤
 * @param props 默认配置将使用img组件的全局配置
 * props的值有 src、domain,baseUrl、remote、remoteUrl、defaultImg、defaultImgUrl、thumb、thumbRule、srcKey
 * @param mergeConfig合并全局配置
 */
import isEmpty from "./isEmpty";
import getObjValue from "./getObjValue";
import isPlainObject from "./isPlainObject";
import platform from "./platform";

const defaultUriConfig = {
    src:null,
    domain:true,
    baseUrl:null,
    remote:false,
    remoteUrl:null,
    defaultImg:true,
    defaultImgUrl:null,
    thumb:null,
    thumbRule:null,
    srcKey:'file'
};

const defaultImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkYwOTk4Q0QyMDVFODExRUVBREQ3RjIxMjBCRTlEOUJGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkYwOTk4Q0QzMDVFODExRUVBREQ3RjIxMjBCRTlEOUJGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RjA5OThDRDAwNUU4MTFFRUFERDdGMjEyMEJFOUQ5QkYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RjA5OThDRDEwNUU4MTFFRUFERDdGMjEyMEJFOUQ5QkYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5oyMQaAAAAD0lEQVR42mJ49+4dQIABAAWYAsuJSDQJAAAAAElFTkSuQmCC';

const platformList = ['vue3','vue2','plus','nvue','h5','weixin','alipay','baidu','toutiao','qq','kuaishou','360','mp','webview','webview-union','webview-huawei'];

function uriFilter(props = {},mergeConfig = true){
    props = Object.assign({},defaultUriConfig,mergeConfig?uni.$c.config.img:{},props);

    // 格式化src
    let src = props.src;
    if(!isEmpty(src)){
        if(typeof src === 'string'){
            if(src.indexOf('data:') === 0 || src.indexOf('blob:') === 0  || src.indexOf('wxfile:') === 0) return src;
            if(src.indexOf('[') === 0 || src.indexOf('{') === 0){
                src = JSON.parse(src);
            }else{
                src = src.split(',');
            }
        }

        if(Array.isArray(src)) src = getObjValue(src,0);

        if(isEmpty(src)){
            src = ''
        }else if(isPlainObject(src)){
            src = getObjValue(src,props.srcKey)
        }
    }

    // 默认图片
    if(isEmpty(src) && props.defaultImg && !isEmpty(props.defaultImgUrl)){
        if(typeof props.defaultImgUrl === 'string') props.defaultImgUrl = {0:props.defaultImgUrl}
        if(typeof props.defaultImg === 'boolean'){
            src = Object.values(props.defaultImgUrl)[0];
        }else if(typeof props.defaultImgUrl[props.defaultImg] !== 'undefined'){
            src = props.defaultImgUrl[props.defaultImg];
        }
    }
    if(isEmpty(src)) return defaultImg;

    // 本地资源、托管在服务器的本地资源
    if(props.remote === true) props.remote = 'mp';
    //非处理
    if(props.remote && props.remote.indexOf('!') === 0){
        props.remote = props.remote.replace('!','');
        props.remote = (platformList.filter(val=>val !== props.remote)).join(',');
    }
    if(isEmpty(props.remoteUrl)) props.remoteUrl = props.baseUrl;
    if(src.indexOf('/static/') === 0){
        if(!isEmpty(props.remoteUrl) && props.remote && (props.remote === 'all' || props.remote.split(',').includes(platform))){
            return props.remoteUrl+src
        }
        return src
    }

    // 补全非本地地址
    if(props.domain === true) props.domain = 'all';
    //if(src.indexOf('//') === 0) src = 'http:'+src;

    //非处理
    if(props.domain && props.domain.indexOf('!') === 0){
        props.domain = props.domain.replace('!','');
        props.domain = (platformList.filter(val=>val !== props.domain)).join(',');
    }

    if(!isEmpty(props.baseUrl)  && src.indexOf('http') !== 0 && src.indexOf('//') !== 0 && props.domain && (props.domain === 'all' || props.domain.split(',').includes(platform))){
        return props.baseUrl+src
    }

    // 缩略图
    // 缩略图生成规则名
    // 目前只支持 aliyun（阿里云），更多规则扩展：
    // Vue.use(cookUni, {
    //    uriThumbRuleExtra:{
    //      规则名：规则方法
    //    }
    // })
    if(!isEmpty(props.thumb) && !isEmpty(props.thumbRule) && typeof uni.$c.uriThumbRuleExtra[props.thumbRule] !== 'undefined'){
        const thumbKey = [],thumbValue = [];
        props.thumb.split('/').forEach((n,i)=>{
            (i+1)%2 !== 0?thumbKey.push(n):thumbValue.push(n);
        });
        const thumbConf = {};
        thumbKey.forEach((n,i)=>{
            thumbConf[n] = thumbValue[i];
        });
        if(isEmpty(thumbConf.w)) thumbConf.w = 'auto';
        if(isEmpty(thumbConf.h)) thumbConf.h = 'auto';
        src = uni.$c.uriThumbRuleExtra[props.thumbRule](src,thumbConf);
    }
    return src;
}

export default uriFilter