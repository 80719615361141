export default {
    // 高度，值：数字、statusBar、navBar、header、window、safeBottom
    height:null,

    // 只有父级为flex-direction: row;才有效，值：数字、window
    width:null,

    // 圆角，radius=round时为圆形
    radius:null,

    // 显示加载中
    loading:false,
    loadingProps:{},
    loadingBg:'rgba(255,255,255,.8)',

    hoverClass:'none',
    // 指定是否阻止本节点的祖先节点出现点击态，微信小程序有效
    hoverStopPropagation: false,
    // 按住后多久出现点击态，单位毫秒
    hoverStartTime: 50,
    // 手指松开后点击态保留时间，单位毫秒
    hoverStayTime: 400,
}