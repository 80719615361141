import App from './App'
import CookUni from '@/uni_modules/cook-uni';
import dayjs from 'dayjs';
import HttpInterceptor from './common/http.interceptor'
import HttpApi from './common/http.api'

import { createSSRApp } from 'vue';
import store from './common/store'

export function createApp() {
  const app = createSSRApp(App);
  // 启动cook-uni
  CookUni(app,{
    dayjs
  });
  HttpInterceptor(app);
  HttpApi(app);

  app.use(store);
  store.dispatch('login');
  return {
    app
  }
}