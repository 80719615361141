export default {
    // 图片
    // 参考c-img组件的props
    img:null,

    // 描述
    desc:'没有数据',

    // 描述配置
    // 参考c-text组件的props
    descProps:{}
}