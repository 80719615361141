import isEmpty from "./isEmpty";

/**
 * 设计稿px转rpx
 * @param num
 * @param showUnit
 * @returns {*}
 */
function toRpx(num,showUnit = true) {
  if(isEmpty(num) || num === 0) return 0 + (showUnit?'rpx':0);
  if(typeof num === 'string'){
    if(num.indexOf('rpx') !== -1 || num.indexOf('upx') !== -1){
      if(showUnit){
        return num.replace('upx','rpx');
      }else{
        num = num.replace('upx','').replace('rpx','');
        return num.indexOf('.') === -1?parseInt(num):parseFloat(num)
      }
    }else if(num.indexOf('px') !== -1){
      num = num.replace('px','');
      return (num.indexOf('.') === -1?parseInt(num):parseFloat(num))/(uni.upx2px(100)/100) + (showUnit?'rpx':0);
    }else{
      num = num.indexOf('.') === -1?parseInt(num):parseFloat(num);
    }
  }
  return Math.round(num/uni.$c.config.designWidth*750*100)/100 + (showUnit?'rpx':0);
}

export default toRpx