export default {
    // 类型
    // 支持scss $colors中扩展的主题
    type:null,

    // 标题
    title:null,

    // 文字描述
    desc:null,

    // 圆角，radius=round时为圆形
    radius:null,

    // 关闭
    closable:false,

    // 是否深色
    dark:false,

    // 内容是否居中
    center:false,

    // 显示icon
    showIcon:false,
}