/**
 * 路由
 */
import isPlainObject from "./isPlainObject";
import merge from "./merge";
import isEmpty from "./isEmpty";
import toUrlParams from "./toUrlParams";

function route(/* config */){
    let config = {};
    let immediate = true;
    if(typeof arguments[0] === 'string'){
        config.url = arguments[0];
        if(isPlainObject(arguments[1])){
            config = merge(arguments[1],config);
            if(arguments[2] === false) immediate = false;
        }else if(arguments[1] === false){
            immediate = false;
        }
    }else if(isPlainObject(arguments[0])){
        config = arguments[0];
        if(arguments[1] === false){
            immediate = false;
        }
    }else{
        return ''
    }
    config = merge({
        type:'navigateTo',
        url:'',
        params:{},
        delta:1,
        animationType:'pop-in',
        animationDuration:300,
        events:{},
        success:function (res){},
        fail:function (res){},
        complete:function (){},
        // 跳转小程序
        appId:null,
        extraData: {},
        envVersion: 'release',
    },config);
    if(!isEmpty(config.appId)){
        config.type = 'mp';
        // #ifndef MP
        return false;
        // #endif
    }
    if(!isEmpty(config.url)){
        // 特殊关键字
        if(config.url === 'back') { // 返回
            config.type = 'navigateBack';
        }else if(config.url === 'video'){ // 跳转内部视频预览页
            config.url = uni.$c.config.videoPreviewPage;
        }else if(config.url === 'home'){ // 跳转首页
            if(isEmpty(uni.$c.config.navBar.homeRoute)) return;
            config = merge(config,uni.$c.config.navBar.homeRoute);
        }else if(config.url.indexOf('http') === 0){ // 外部链接跳转
            config.type = 'link';
            config.link = config.url;
            config.url = uni.$c.config.webviewPage;
        }

        // 参数处理
        if(!isEmpty(config.params) && config.type !== 'link'){
            let url = config.url.split('?');
            if(typeof url[1] !== 'undefined' && !isEmpty(url[1])){
                config.url += '&'+toUrlParams(config.params,false);
            }else{
                config.url += toUrlParams(config.params);
            }
        }
        // 标准化路由url
        if(config.url.indexOf('/') !== 0) config.url = '/'+config.url;
    }
    if(!immediate){
        config.params = {};
        return config;
    }
    switch (config.type) {
        case "navigateTo":
        case "to":
        case "link":
            uni.navigateTo({
                url: config.url,
                animationType:config.effect,
                animationDuration:config.duration,
                events:config.events,
                success:function (res){
                    if(config.type === 'link'){
                        res.eventChannel.emit('pageOnload',Object.assign({},config.params,{
                            url:config.link
                        }));
                        config.success();
                    }else{
                        config.success(res.eventChannel);
                    }
                },
                fail:config.fail,
                complete:config.complete
            });
            break;
        case "redirectTo":
        case "rto":
            uni.redirectTo({
                url: config.url,
                success:config.success,
                fail:config.fail,
                complete:config.complete
            });
            break;
        case "switchTab":
        case "tab":
            uni.switchTab({
                url: config.url,
                success:config.success,
                fail:config.fail,
                complete:config.complete
            });
            break;
        case "reLaunch":
        case "lch":
            uni.reLaunch({
                url: config.url,
                success:config.success,
                fail:config.fail,
                complete:config.complete
            });
            break;
        case "navigateBack":
        case "back":
            uni.navigateBack({
                delta: config.delta,
                animationType:config.effect,
                animationDuration:config.duration,
                success:config.success,
                fail:config.fail,
                complete:config.complete
            });
            break;
        case "preloadPage":
        case "pre":
            uni.preloadPage({
                url: config.url,
                success:config.success,
                fail:config.fail,
                complete:config.complete
            });
            break;
        // #ifdef MP
        case "mp":
            uni.navigateToMiniProgram({
                appId: config.app,
                path: config.url,
                extraData: config.extraData,
                envVersion: config.envVersion,
                success:config.success,
                fail:config.fail,
                complete:config.complete,
            })
            break;
        // #endif
    }
}
export default route