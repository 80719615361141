export default {
    modelValue: null,

    // 水印
    placeholder: "请输入关键词",

    // 水印颜色
    // 支持c-t-开头的类(字节跳动系列小程序不支持)
    placeholderColor:'c-t-input-placeholder',

    // 边框
    radius: null,

    // 搜索图标
    searchIcon:'search',

    // 显示清除案例
    clearBtn: true,

    // 清除图标
    // 参考c-icon的props
    clearIcon:'error-fill',

    // 显示取消按钮
    // 可选的值always 一直显示, auto 输入框不为空时显示, none 一直不显示
    cancelBtn: "auto",

    // 取消文字
    // 参考c-text的props
    cancelText:'取消',

    // 高度
    height:null,

    // 默认位置
    center:true,

    // 输入框背景
    inputBgColor:null,

    // 文字颜色，支持c-t-开头的类
    color:null,

    // 文字大小
    fontSize:null,

    // 边框
    // 为object时{borderWidth，borderStyle，borderColor}
    border:true,

    // 最大输入长度
    maxlength: -1,

    // 自动获取焦点
    focus: false,

    // 是否只读
    readonly: false,

    // 自定义input的样式
    inputStyle:{}
}