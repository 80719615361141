/**
 * 获取页面或者父组件实例
 * 使用示例： uni.$c.$parent.call(this,'myParentComponent');
 * @param name 不传则默认查找页面(最顶层)的实例
 * @returns {boolean|*}
 */
function getParent(name){
    let parent = this.$parent
    while (parent) {
        if (parent.$options && parent.$options.name !== name) {
            parent = parent.$parent
        } else {
            return parent
        }
    }
    return false
}

export default getParent