export default {
    // 下拉刷新配置
    down:{
        /*use: false,
        offset: '45px',// 下拉触发距离
        beforeEndDelay: 800, // 调用endSuccess或endError后延迟多久关闭刷新框
        // 下拉时指示器的样式
        // true和loadMore.loadingIndicatorProps一致，false不显示
        indicatorProps:true,
        loadMore:{
            indicatorPosition:'left',
            indicatorGutter:'10rpx',
            loadMoreText: '下拉刷新',
            noMoreText: "释放更新",
            loadingText: "正在刷新",
            successText: {
                text:"刷新成功",
                color:'c-t-success'
            },
            errorText: {
                text:"刷新失败",
                color:'c-t-danger'
            },
            successIcon:'success',
            errorIcon:'error',
        }*/
    },

    // 上拉加载配置
    up:{
        /*use: false,
        auto: true, // 自动加载第一项
        offset: '100px', // 距离底部触发距离
        pageSize: 10,
        noMoreSize: 5, // 避免列表数据过少(比如只有一条数据),显示无更多数据会不好看。其值必须<=upOption.pageSize的值
        loadMore:{
            height:'80rpx',
            indicatorPosition:'left',
            indicatorGutter:'10rpx',
            loadMoreText: '上拉加载更多',
            loadingText: '正在加载...',
            noMoreText: '-- 没有更多了 --',
            errorIcon:'refresh',
            errorText: {
                text:"加载失败，点击重试",
                color:'c-t-danger'
            },
            errorBgColor:'c-bg-danger-light-8',
        },
        pageType:'pageSize', //判断是否有分页的类型：pageSize总页数，dataSize 总数据，hasPage 是否有下一页*/
    },

    // 空状态配置
    emptyProps:{},
}