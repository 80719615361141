import isEmpty from "./isEmpty";

/**
 * 设计稿px转upx
 * @param num 为数字时被认为是upx单位
 * @param showUnit
 * @returns {*}
 */
function toPx(num,showUnit = true) {
  let res;
  if(isEmpty(num) || num === 0){
    res = 0;
  }else if (typeof num === 'number') {
    res = uni.upx2px(num);
  }else if (num.indexOf('rpx') >= 0) {
    res = uni.upx2px(Number(num.replace('rpx','')));
  }else if (num.indexOf('upx') >= 0) {
    res = uni.upx2px(Number(num.replace('upx','')));
  }else if (num.indexOf('px') >= 0) {
    res = Number(num.replace('px',''));
  }else{
    res = uni.upx2px(Number(num));
  }
  return res + (showUnit?'px':0);
}

export default toPx