/**
 * 编译平台
 *
 * 此属性用于获取当前运行的平台名称，相较于uni-app系统自带的条件编译的区别是，此方式让我们可以通过js的if | else if进行判断， 您可以结合实际场景进行使用。
 * 注意：此属性返回的结果，和uni-app的条件编译名称有差别，并且结果都为小写。
 */

let platform = 'none'

// #ifdef VUE3
platform = 'vue3'
// #endif

// #ifdef VUE2
platform = 'vue2'
// #endif

// #ifdef APP-PLUS
platform = 'plus'
// #endif

// #ifdef APP-NVUE
platform = 'nvue'
// #endif

// #ifdef H5
platform = 'h5'
// #endif

// #ifdef MP-WEIXIN
platform = 'weixin'
// #endif

// #ifdef MP-ALIPAY
platform = 'alipay'
// #endif

// #ifdef MP-BAIDU
platform = 'baidu'
// #endif

// #ifdef MP-TOUTIAO
platform = 'toutiao'
// #endif

// #ifdef MP-QQ
platform = 'qq'
// #endif

// #ifdef MP-KUAISHOU
platform = 'kuaishou'
// #endif

// #ifdef MP-360
platform = '360'
// #endif

// #ifdef MP
platform = 'mp'
// #endif

// #ifdef QUICKAPP-WEBVIEW
platform = 'quickapp-webview'
// #endif

// #ifdef QUICKAPP-WEBVIEW-HUAWEI
platform = 'quickapp-webview-huawei'
// #endif

// #ifdef QUICKAPP-WEBVIEW-UNION
platform = 'quckapp-webview-union'
// #endif

export default platform
