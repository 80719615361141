import mathMultiply from "./mathMultiply"

/**
 * 减法
 *
 * @since 4.0.0
 * @category Math
 * @param {number} minuend The first number in a subtraction.
 * @param {number} subtrahend The second number in a subtraction.
 * @returns {number} Returns the difference.
 * @example
 *
 * subtract(6, 4)
 * // => 2
 */
function mathSubtract(minuend, subtrahend) {
  let c, d, e;
  try {
    c = minuend.toString().split(".")[1].length;
  } catch (f) {
    c = 0;
  }
  try {
    d = subtrahend.toString().split(".")[1].length;
  } catch (f) {
    d = 0;
  }
  return e = Math.pow(10, Math.max(c, d)), (mathMultiply(minuend, e) - mathMultiply(subtrahend, e)) / e;
}

export default mathSubtract
