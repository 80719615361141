import forEach from "./forEach";

/**
 * 获取JSONArray列
 * @param arrayData {Array}
 * @param fieldName {String}
 */
function getJSONArrCol(arrayData, fieldName){
    if (!arrayData) arrayData = [];
    let res = [];
    forEach(arrayData,function(n, i) {
        if (typeof n[fieldName] !== 'undefined') {
            res.push(n[fieldName]);
        }
    })
    return res;
}

export default getJSONArrCol