/**
 * 设置配置
 */
import isEmpty from "./isEmpty";
import merge from "./merge";

function setConfig(name,value = {}){
    if(typeof uni.$c.config[name] === 'undefined') return;
    if(name !== 'designWidth' && isEmpty(value)) value = {};
    uni.$c.config[name] = merge(uni.$c.config[name],value);
    if(name === 'http'){
        uni.$c.http.setConfig(conf=>{
            return merge(conf,uni.$c.config.http);
        });
    }
}

export default setConfig