export default {
  // 标识
  name:null,

  // 禁用
  disabled: null,

  // 选中时的值
  value:true,

  // 未选中时的值
  inactiveValue:false,

  // 是否开启异步变更，开启后需要手动控制输入值
  asyncChange:false,

  // loading项配置
  // 参考c-loading-indicator组件的props
  loadingProps:{},

  // 未选中时的背景颜色
  // 支持c-bg-开头的类
  color:null,

  // 选中时颜色
  // 支持c-bg-开头的类
  activeColor:null,

  // 未选中文字
  // 为object时参考c-text的props
  text:null,

  // 选中文字
  // 为object时参考c-text的props
  activeText:null,

  // 文本尺寸
  textSize:null,

  // 尺寸
  size:null,

  // 自定义圆圈样式
  circleStyle:{},

  // 自定义样式
  btnStyle:{}
}