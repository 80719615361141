export default {
    // 是否垂直类型
    vertical: false,

    // 分割线长度
    // vertical=true 表示高度 默认26rpx，vertical=false表示宽度
    size: null,

    // 分割线宽度
    borderWidth: '0.5px',

    // 边框样式
    borderStyle: 'solid',

    // 分隔线颜色
    // 支持c-bdr-开头的颜色类
    borderColor: 'c-bdr-base',

    // 以下 vertical=false时有效

    // 分割线内容左右边距
    contentSpace: '16rpx',

    // 分割线内容位置， left right center
    contentAlign: 'center',

    // 内容的偏移量
    // contentAlign=left指内容在左边的偏移
    // contentAlign=right指内容在右边的偏移
    // contentAlign=center 无效
    contentOffset: null,
}